import styled, { createGlobalStyle } from "styled-components";


export const theme = {
    color: {
                white: "#fff",
                orange: "#FFAF00",
                primary: "#5ccc9c",
                lightTile: "#e9f3f0 ",
                text: "#c6c8cc",
                textLight: "#f7f8fa",
                textDark: "#1d2a59",
                navLinks: "#1b213679",
                blue_secondary: "#0647fb",
                blue_primary: "#4b59f7",
                bg_gradient:"linear-gradient(0deg, rgba(255,255,255,1) 8%, rgba(92,204,156,1) 100%)",
                bg_gradient_blue: "rgba(29,42,89,1)",

    },
}


const GlobalStyle = createGlobalStyle`

#root {
    white-space: pre-line;
  }



*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
}
`

export const  Container = styled.div`
width: 100%;
max-width: 1200px;
margin: 0 auto;
padding: ${({success}) => success?  "200px 50px" : "0 50px"};
text-align: ${({career, success}) => career ? "right" : success?  "center" : ""};

& > img {
    margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
    text-align: ${({textAlign}) => textAlign};
    padding: 0 30px;
   
}

@media screen and (max-width: 768px) {
    padding: 0 10px;
    
}

`
export const  FlexRow = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({reverseImage}) => reverseImage ? "row-reverse" : "row"};

& > div, & > ul{
    flex: 1;
}

@media screen and (max-width: 992px) {
  flex-direction: column;

  ul{
      padding: 0px;
      margin-top: 10px;
  }
    
}


`

export const  Button = styled.button`
border-radius: 4px;
background-color: ${({primary, theme})  => (primary ? theme.color.blue_primary : theme.color.blue_secondary)};
color: #fff;
padding: ${({big}) => (big? "12px 64px" : "10px 20px")};
font-size: ${({bigFont}) => (bigFont? "20px" : "16px")};
border: none;
outline: none;
cursor: pointer;


&:hover{
    transition: all ease 0.3s;
    background-color: #fff;
    background-color: ${({primary, theme})  => (primary ? theme.color.blue_secondary : theme.color.blue_primary)};
    transform: scale(0.98);
}

@media screen and (max-width: 992px) {
    width: 100%;
    
}

`


export default GlobalStyle