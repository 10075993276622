import React from 'react'
import { Container } from '../../globalStyle'
import SocialIcons from '../Footer/SocialIcons'



const SuccessPage = () => {


  // console.log(location.state.error);

  return(
    <Container center={"center"} success={true} >
      <h1>Your application was send successfully.</h1><br />
      <SocialIcons success={true}  />
    </Container>
  
  )
}

export default  SuccessPage
  
