import React from 'react'
import ApplyFormSection from '../../components/ApplyForm/ApplyForm'



const ApplyForm = () => {
  return (
       <ApplyFormSection/>
  )
}

export default ApplyForm