import React from 'react'
import { AccordionBody, AccordionItem, AccordionWrapper, HeaderWrapper, IconWraper,AccordionContainer } from './Accordion.styled';
import {FaAngleUp, FaAngleDown, FaTwitter} from 'react-icons/fa'
import { useState } from 'react';
import { accordionData } from '../../pages/Career/carrerData';


const AccordionItems = ({handleSelectdItem}) =>{  
  const [open, setOpen] = useState(false)
  const [currentAccordion, setCurrentAccordion] = useState(1);


  const handleClick = (id) => {
    setCurrentAccordion(id)
    handleSelectdItem(id)
  }


  return(

    <AccordionContainer>
     { accordionData.map((item, index) => (
      <AccordionWrapper key={index} >
      <AccordionItem >
          <HeaderWrapper onClick={() => handleClick(item.id)} >
            <h1 >{item.title}</h1>
            <IconWraper >
              {(currentAccordion === index)?   <FaAngleUp /> : <FaAngleDown /> }
            </IconWraper>
          </HeaderWrapper >
      <AccordionBody open={currentAccordion === index} >
        <p>{item.content}</p>
      </AccordionBody>
      </AccordionItem>
    </AccordionWrapper>
  
      ))}
    </AccordionContainer>

  
  
  )
}

export default  AccordionItems
  
