import styled from "styled-components";

export const  CardStyled = styled.div`
/* padding: 20px 40px; */
padding-bottom: 20px;
margin: ${({career}) => career ? "20px 40px" : "10px 20px"  };
border-radius: 8px;
border: none ;
color: ${({theme: {color: {textDark}}}) => textDark};
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
text-align: center;
height: 647px;
box-shadow: 0 0 10px rgba(92,204,156,0.45);
overflow: hidden;

h1{
    color: ${({theme: {color: {textDark}}}) => textDark};
    margin:20px auto ;
}

h3{
    color: ${({theme: {color: {blue_primary}}}) => blue_primary};
    margin-bottom: 40px;

}
img{

}

p{
    text-align: justify;
    line-height: 1.4;
    font-size: 18px;
    /* opacity: 0.7; */
}


@media screen and (max-width: 992px) {
    margin: 20px 0px;
    padding: 10px 20px;

    h1{
    margin: 0px;
    margin-bottom: 10px;
    }

    h3{
        margin: 0px;
        margin-bottom: 10px;


    }

    p{
        text-align: center ;
    }
}


`

export const  ContentWrapper = styled.div`
margin: 20px 40px;
text-align: center;
`
