import React from 'react'
import { InfoSection } from '../../components'
import { CareerObjOne, CareerObjTwo } from '../HomePage/Data'

const JobDescription = () => {
  return (
    <>
    <InfoSection {...CareerObjTwo} />
    </>
  )
}

export default JobDescription