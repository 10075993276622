import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button, Container, FlexRow } from '../../globalStyle'
import { accordionData } from '../../pages/Career/carrerData'
import AccordionItems from '../Accordion/Accordion'
import Card from '../Card/Card'
import scrollReveal from "scrollreveal";
import { DecriptionParagraph, Heading, InfoSec, TextWrapper, TopLine, ImgWrapper, Image } from './InfoSection.styled'

const InfoSection = ( {cardData, id,lightBg,marginMin,gradientImage, gradient,second,hero,blueGradient,  imageBg, last,career, reverseImage, img, topLine, headline, description, buttonLabel, lightTopLine, lightText, lightTextDesc, isSticky}) => {
    const [currentAccordion, setCurrentAccordion] = useState(0);
    const [getData, setData] = useState({});
    const navigate = useNavigate();


    // useEffect(() => {
    // scrollReveal().reveal('.headline', {reset: false, delay: 200});
    // })

    useEffect(() => {

        accordionData.map(item => {
            currentAccordion === item.id && setData(item)
        }) 
    },[currentAccordion])

    const handleApply  = (data) => {
        navigate(`/career/job_apply`)
    }

    const MapCards = () => cardData.map(card => <Card key={card.id} data={card} />)



  return (
    <InfoSec id={id} marginMin={marginMin} gradientImage={gradientImage} blueGradien={blueGradient} imageBg={imageBg} hero={hero} lightBg={lightBg} gradient={gradient} second={second} >
        <Container  className='headline'>
        <FlexRow reverseImage={reverseImage} >
            <TextWrapper career={career} last={last}>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <DecriptionParagraph lightTextDesc={lightTextDesc}>{description}</DecriptionParagraph>
                <Link to="/career/job_desc" >
                   {imageBg && <Button primary >{buttonLabel}</Button>}
                </Link>
            </TextWrapper>
            {last ?
            (<Container success >
                 <Heading lightText={lightText}>{headline}</Heading>
                <DecriptionParagraph lightTextDesc={lightTextDesc}>{description}</DecriptionParagraph>
                <FlexRow>
                {/* <Card data={""} />
                <Card data={""} /> */}
                <MapCards/>
            </FlexRow>
            </Container>) : 
            career ?  
            (<Container career={career} >
                <TopLine lightTopLine={lightTopLine}>Job description:</TopLine>
                <AccordionItems handleSelectdItem={(e) => setCurrentAccordion(e)} />
                <Button onClick={() => handleApply(getData)} primary >{buttonLabel}</Button>
            </Container>) :
            (<ImgWrapper hero={hero} gradientImage={gradientImage} >
                <Image src={img} alt="" />
            </ImgWrapper>)
            }
        </FlexRow >
        </Container>
    </InfoSec>
  )
}

export default InfoSection