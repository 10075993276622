import {FaFacebook, FaLinkedin, FaArrowLeft} from 'react-icons/fa'
import { SocialStyled } from './Social.stiled'


const SocialIcons = ({success}) => {
  return (
    success ?  ( <SocialStyled>
      <li><a href="/"><FaArrowLeft/></a></li>
</SocialStyled>) : (  <SocialStyled>
            <li><a href="https://www.facebook.com/meta5team/?ref=pages_you_manage" target="_blank"><FaFacebook/></a></li>
            <li><a href="https://www.linkedin.com/company/meta-5-team/about/" target="_blank"><FaLinkedin/></a></li>
    </SocialStyled>)
  )
}

export default SocialIcons