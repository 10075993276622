import React from 'react'
import { InfoSection } from '../../components'
import Footer from '../../components/Footer/Footer'
import InfoSectionTwo from '../../components/InfoSection/InfoSectionTwo'
import LandingScrollToTop from '../../components/LandingScrollToTop/LandingScrollToTop'
import{homeObjOne, homeObjTwo,homeObjThree,homeObjFour,homeObjFive, cardData} from "../HomePage/Data"


const Home = () => {


  return (
      <>
    <InfoSection {...homeObjOne} />
    <InfoSectionTwo />
    <InfoSection {...homeObjThree} />
    <InfoSection {...homeObjFour} />
    <InfoSection {...homeObjFive}  cardData={cardData} />
    <LandingScrollToTop />
      </>
  )
}

export default Home