
// HOME PAGE
export const homeObjOne = {
  id: "hero",

        hero : true,
        imageBg: true,
        primary: false,
        lightBg: false,
        gradient: false,
        lightBgSec: false,
        lightTopLine: true,
        lightText: false,
        lightTextDesc: false,
        topLine: 'We create growth',
        headline: 'Lead Generation Specialist',
        description:
          'The humble and truly passionate team behind our brands do not only come up with new and creative ideas, but also have a great track record in executing projects with agility. Thanks to our great team, Meta5 has become one of the industry’s most reputable companies.',
        buttonLabel: 'Join Us',
        reverseImage: false,
        img: '',
        alt: 'Credit Card',
        start: '',
        marginMin: true
}

export const homeObjTwo = {
  id: "homeTwo",

  hero : false,
  imageBg: false,

  primary: true,
  lightBg: false,
  gradient: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Extremely quick onboarding process',
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'Learn More',
  reverseImage: true,
  img: './assets/test1.jpg',
  alt: 'Vault',
  start: '',
  marginMin: false


};

export const homeObjThree = {
  id: "about",

  hero : false,
  imageBg: false,

  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Always on the edge',
  headline:
    'Accelerate your digital transformation with Us!',
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.Once you've joined, our team of specialist will reach out to you and get you set up in minutes.Once you've joined, our team of specialist will reach out to you and get you set up in minutes. \n\n Once you've joined, our team of specialist will reach out to you and get you set up in minutes.Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'View Case Study',
  reverseImage: true,
  img: './assets/988.jpg',
  alt: 'Vault',
  start: 'true',
  marginMin: false


};

export const homeObjFour = {
  id: "services",
  hero : false,
  imageBg: true,
  primary: false,
  lightBg: false,
  gradient: true,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Inspiring environment',
  headline: 'A great place to grow',
  description:
    'We encourages people who are looking to reach their highest potential. We’ll hire the best person for the job every time, full stop.',
  buttonLabel: 'Join Our Team',
  reverseImage: false,
  img: './assets/metaHero.jpeg',
  alt: 'Vault',
  start: 'true',
  marginMin: false,
  blueGradient: true,



};

export const homeObjFive= {
  id: "homeObjFive",
  hero : false,
  imageBg: false,
  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sarah Jeni',
  headline:
    'Ultra helped me increase my revenue by over 3X in less than 3 months!',
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: 'View Case Study',
  reverseImage: true,
  img1: './assets/14.jpg',
  img2: './assets/14.jpg',
  img3: './assets/14.jpg',
  alt: 'Vault',
  last: true,
  career: false,
  start: 'true',
  marginMin: false


};

// Career
export const CareerObjOne = {
        id: "#JoinUs",
        career: false,
        hero : false,
        imageBg: false,
        primary: true,
        lightBg: false,
        gradient: false,
        gradientImage: true,
        lightBgSec: false,
        lightTopLine: true,
        lightText: false,
        lightTextDesc: false,
        topLine: '',
        headline: 'Join Us!',
        description:
          'Come and work in a friendly and dynamic environment alongside the most talented people in the field! \n\nHere at Meta5Team, we hire people who have the drive to be the best. We would like you to discover and realize your full potential, execute your ideas and change the world as it is into the world as it ought to be. \n\n Start your career with us!',
        buttonLabel: '',
        reverseImage: false,
        img: './assets/svg-1.svg',
        alt: '',
        start: '',
        marginMin: true

};

export const CareerObjTwo = {
  id: "appply",
  hero : false,
  imageBg: false,
  career: true,
  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sarah Jeni',
  headline:
    'Ultra helped me increase my revenue by over 3X in less than 3 months!',
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: 'Apply',
  reverseImage: true,
  img: './assets/14.jpg',
  alt: 'Vault',
  last: false,
  career: true,
  start: 'true',
  marginMin: false

}

export const  cardData = [
  {
    id:0,
    title: "Title One",
    subtitile: "subtitle one",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi quaerat, nobis ducimus amet nihil deserunt quod eveniet molestias illum quidem omnis quisquam, non necessitatibus.",
    img: "./assets/977.jpg"
  

},

{
  id:1,
  title: "Title Two",
  subtitile: "subtitle two",
  description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi quaerat, nobis ducimus amet nihil deserunt quod eveniet molestias illum quidem omnis quisquam, non necessitatibus.",
  img: "./assets/966.jpg"


}


]



