import React from 'react'
import { Container } from '../../globalStyle'
import { DecriptionParagraph, Heading, Image,TopLine, ImgWrapper, InfoSec, TextWrapper, ContainerTwo, ContainerInner } from './InfoSectionTwo.styled'

const InfoSectionTwo = () => {
  return (
    <InfoSec>
    <ContainerTwo>
        <ImgWrapper  >
                <Image src={"./assets/test1.jpg"} alt="" />
        </ImgWrapper>
            <TextWrapper >
                <Heading >...vision</Heading>
                <DecriptionParagraph >
                Our company is dedicated to the highest quality of development, software solutions with cutting edge texnologies. The mission is to build the best products .... ....  As far as we are moving the development proccesses foward we are abble to deliverd the best solutions for our Clients. 
                </DecriptionParagraph>
            </TextWrapper>
    </ContainerTwo>
    </InfoSec>
  )
}

export default InfoSectionTwo