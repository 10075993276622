import styled from "styled-components";

export const FooterStyled = styled.footer`
background-color: #fff;
color: ${({theme: {color: textDark}}) => textDark};
padding-top: 20px;
box-shadow:0px 0px 12px 2px #f7f8fa  ;

ul{
    list-style-type: none;
    /* opacity: 0.7; */
}

ul li {
    margin-bottom: 12px;
    cursor: pointer;

}

a{
    text-decoration: none;
    color: inherit;

}

p {
    text-align: right;
    position: absolute;
    align-self: center;
    color: ${({theme: {color: textDark}}) => textDark};
    /* opacity: 0.7; */


}

ul li:first-child{
    font-weight: 600;
}


@media(max-width: 992px){
    text-align: center;
    padding-top: 20px;


    ul{
    padding-right: 0px;
}

ul li{
    margin-top: 10px;
}


}



@media(max-width: 768px){
    text-align: center;

    ul li {
        margin-bottom: 0;
    }

    p{
        text-align: center;

    }


}



`

export const AllRightsWrapper  = styled.div`
    display: flex;
    padding: 30px 0px;
    justify-content: center;
    margin-top: 20px;
    border-top: 1px solid #f7f8fa ;

    @media(max-width: 992px){ 
        margin-top: 0px;
}


`