import React from 'react'
import { CardStyled, ContentWrapper} from './Card.styled'

const Card = ({data}) => { 

  return (
    <CardStyled  career={data === ""}>
       <img width={"100%"} src={data.img} alt="" />
      <ContentWrapper>
        <h1>{data.title}</h1>
        <h3>{data.subtitile}</h3>
        <p>{ data.description}</p>
        </ContentWrapper>

    </CardStyled>
  )
}

export default Card