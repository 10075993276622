import styled from "styled-components";

export const SocialStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
align-self: flex-end;
margin-bottom: 10px;
width: 100%;

li {
    list-style: none;
}

a{
    display: flex;
    border: ${({theme : {color: {orange}}}) => "1px solid orange"} ;
    color: ${({theme: {color: textDark}}) => textDark};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    height: 46px;
    width: 46px;


    &:hover{
        transition: all ease 0.3s;
        transform: scale(1.1);
    }
}

@media screen and (max-width: 992px) {

    padding: 20px 0px;
    justify-content: center;

}

`

