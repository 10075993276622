import React, { useEffect, useState } from "react";
import classes from "../LandingScrollToTop/LandingScrollToTop.module.css";
import {FaArrowUp} from 'react-icons/fa'
import { NavHashLink } from "../Navbar/Navbar.styled";

export default function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > 650) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", toggleVisibility);
		return ()=> {
			window.removeEventListener('scroll',toggleVisibility)
		}
	}, []);

	return (
		<button
			className={`${classes.scroll__top} ${isVisible && classes.scroll__top__visible}`}
			onClick={scrollToTop}
		>
			up!
		</button>
	);
}
