import styled from "styled-components";

export const AccordionContainer = styled.div`
    width: 100%;
    max-height: 100%;
`

export const  AccordionWrapper = styled.div`
padding: 20px ;
border-radius: 8px;
margin-bottom: 20px;
border: none ;
color: ${({theme: {color: {textDark}}}) => textDark};
display: flex;
flex-direction: column;
align-items: flex-start;
text-align: center;
box-shadow: 0 0 10px rgba(92,204,156,0.45);
max-height: fit-content;
overflow: auto;


&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}


p{
    text-align: justify;
    line-height: 1.4;
    /* opacity: 0.7; */
}

`

export const  AccordionItem = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
`

export const  HeaderWrapper = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: space-between;
color: ${({theme: {color: {textDark}}}) => textDark};

`

export const  AccordionBody = styled.div`
display: ${({open}) => !open && "none"};
overflow: auto;
scroll-behavior: smooth;
margin-top: 20px;


`
export const  IconWraper = styled.div`
height: 32px;
width: 32px;
display: flex;
justify-content: center;
align-items: center;



`

export const  AccordionIcon = styled.img`


`
