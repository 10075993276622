
import React from "react";
import {
 ModalBlock,
 ModalBody,
 ModalClose,
 ModalContainer,
 ModalFooter,
 ModalHeader,
 ModalOverlay,
 ModalTitle,
} from "./Modal.styled";

            const Modal = ({ title, footer, children, active, hideModal }) => {
            return (
            <>
            {active && (
            <ModalBlock>
            <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
            <ModalContainer>
            <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            {/* <ModalClose onClick={() => hideModal()}>X</ModalClose> */}
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
            </ModalContainer>
            </ModalBlock>
            )}
            </>
            );
            };

export default Modal