import styled from "styled-components"

export const  InfoSec = styled.div`
  margin-top: ${({marginMin}) => marginMin   &&  "-80px" };
  color: #fff;
  padding: 160px 0px;
  background: ${({ lightBg, gradient,hero, theme:{ color:{white, primary, bg_gradient, bg_gradient_blue}} }) => (gradient ?  bg_gradient_blue : lightBg ? white : !hero ? primary : "")};
  background-image:  ${({hero,gradientImage}) =>  
  hero  ? 
  "linear-gradient(to bottom, rgba(255,255,255,0.52), rgba(92,204,156,1)), url(../../assets/test1.jpg) " :
   gradientImage ? 
   "linear-gradient(to top, rgba(255,255,255,1), rgba(92,204,156,0.72)), url(../../assets/JoinUs.png)" : "" } ;
  background-repeat: no-repeat;
  background-position: ${({gradientImage}) =>  gradientImage ? "center"  : "top"};
  background-size: cover;

  @media screen and (max-width: 992px) {
    padding: 120px 10px;

    
  }

`
export const  InfoSecImg = styled.img`
height: 200px;
width: 400px; 
`

export const TextWrapper = styled.div`
  display: ${({last, career}) => (last || career) && "none"};
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine, theme:{color: {orange,blue_primary }} }) => (lightTopLine ? orange : blue_primary)};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 64px;
  line-height: 1.1;
  font-weight: 700;
  color: ${({ lightText, theme:{color: {textLight, textDark}}}) => (!lightText ? textDark : textLight)};

  @media screen and (max-width: 992px) {
    font-size: 44px;
    
  }

`;

export const DecriptionParagraph = styled.p`
  max-width: 100%;
  margin-bottom: 35px;
  font-size: 21px;
  line-height: 24px;
  color: ${({ lightTextDesc, theme:{color: {lightTile, textDark, navLinks}}  }) => (!lightTextDesc ? textDark : lightTile)};
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: ${({hero, gradientImage}) =>  gradientImage ? "none" : "flex"};
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  padding: 0 2rem;
`;

export const Image = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  border-radius: 8px;
  

`;
