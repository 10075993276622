import React from 'react'
import { Outlet } from 'react-router-dom'
import { InfoSection } from '../../components'
import { CareerObjOne, CareerObjTwo } from '../HomePage/Data'

const Career = () => {
  return (
    <>
    <InfoSection {...CareerObjOne} />
    <Outlet/>
    </>
  )
}

export default Career