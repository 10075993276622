import styled from "styled-components"

export const  InfoSec = styled.div`
  padding: 160px 0px;
  background: ${({ theme:{ color:{primary}} }) =>  primary };
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width:992px) {
    padding: 80px 10px;

    
  }
`

export const  ContainerTwo = styled.div`
display: flex;
justify-content: center;
margin: 0 auto;
flex-direction: row;
width: 1440px;
padding-left: 180px;

@media screen and (max-width: 1220px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    padding: 0px


  
}

`


export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(29, 42, 89, 0.2);
  transform: translate(-200px, 100px);
  border-radius: 8px;
  /* color: white; */
  padding: 20px;

  @media screen and (max-width: 1200px) {
    transform: translate(0px, -100px);
    align-self: center;
  
}
  
`

export const TopLine = styled.div`
  color: ${({theme:{color: {orange}} }) => orange};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({theme:{color: {orange}} }) => orange};
`;

export const DecriptionParagraph = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 21px;
  line-height: 24px;
  color: ${({ lightTopLine, theme:{color: {orange,blue_primary, white }} }) => white};

  @media screen and (max-width:992px) {
    margin-top: 12px;

    
  }
`;

export const ImgWrapper = styled.div`
  max-width: 600px;
  display: ${({hero, gradientImage}) =>  gradientImage ? "none" : "flex"};
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  /* padding: 0 2rem; */
`;

export const Image = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  border-radius: 8px;

  @media screen and (max-width:576px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    
  }


`;
