import { Navbar } from "./components";
import GlobalStyle, { theme } from "./globalStyle";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {IconContext}  from "react-icons/lib"
import Home from "./pages/HomePage/Home";
import { ThemeProvider } from "styled-components";
import Career from "./pages/Career/Career";
import Footer from "./components/Footer/Footer";
import ApplyForm from "./pages/Career/ApplyForm";
import JobDescription from "./pages/Career/JobDescription";
import SuccessPage from "./components/ApplyForm/SuccessApply";

function App() {
  return (
    <ThemeProvider theme={theme}>
    <IconContext.Provider  value={{color:" #FFAF00"}}>
      <Router>
        <GlobalStyle/>
         <Navbar/>
         <Routes>
           <Route  path="/" element={<Home/>} />
           <Route  path="career" element={<Career/>} >
              <Route  path="job_desc" element={<JobDescription />} />
              <Route  path="job_apply" element={<ApplyForm/>} />
              <Route  path="success" element={<SuccessPage/>} />
            </Route>
         </Routes>
         <Footer/>
      </Router>
    </IconContext.Provider>
    </ThemeProvider>
  );
}

export default App;
